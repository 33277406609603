import React from "react";

const Purpose = () => {
    return (
        <div id="purpose" className={"guide-paragraph"}>
            <h2>
                1. Purpose of the application
            </h2>
            <p>
                Are you familiar with the situation when during the working day you never get up from your
                workplace? It used to be a common situation for me. After a certain amount of time working
                in
                this mode I started to notice that I feel myself completely exhausted, I didn’t have any
                resources left by the end of the day. I didn’t like this feeling at all, so I began to study
                this issue. After reading some articles and recommendations on how to organize working
                hours, I
                found my main problem – I didn’t take any breaks during my work.
            </p>
            <p>
                Many studies say that taking frequent short breaks during work is the key to high
                productivity
                and well-being. During continuous work the brain is overloaded, thoughts are scattered and
                this
                negatively affects your productivity. Sitting still at the workplace leads to poor blood
                circulation, pain in joints and muscles and vision deterioration. This is why it is very
                important to take breaks during work.
            </p>
            <p>
                The so called Pomodoro Technique fits well to the requirements of regular breaks during
                work.
                The essence of the Pomodoro Technique is to break tasks into 25-minute intervals called
                “pomodoro”, followed by a 5-minute rest after each interval. At the same time, after every
                4th
                pomodoro, you should take a long break from 15 to 30 minutes. As for me, I decided that
                during
                the rest you need to spend it with benefit – to stretch the muscles, do exercises for the
                eyes,
                or just get some tea with cookies – the main thing is to get up from the desk and give the
                brain
                a break.
            </p>
            <p>
                After a week of using this technique, I began to feel positive results – by the end of the
                day I
                felt much better, and my productivity increased.
            </p>
            <p>
                I started looking for an app that could remind me to take breaks during work, view
                statistics,
                and include task time management. I could not find a convenient application that would
                satisfy
                all my needs, so I decided to create it myself. I hope you will improve your well-being and
                become more productive with the help of my app.
            </p>
        </div>
    )
}

export default Purpose